import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { PrDocsStatus, PrStatus, makeDocPath, makeGithubPrUrl } from "@cloudy/utils/common";
import { BookCheckIcon, BookDashedIcon, ExternalLinkIcon, GitMergeIcon, GitPullRequestArrowIcon, GitPullRequestClosedIcon, XIcon, } from "lucide-react";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { Button } from "src/components/Button";
import LoadingSpinner from "src/components/LoadingSpinner";
import { useWorkspace } from "src/stores/workspace";
import { cn } from "src/utils";
import { useProject } from "../projects/ProjectContext";
import { DocumentCard } from "./DocumentCard";
import { useConfirmAllPrDocs, useDraftPrDocs, usePrDetail, useSkipPrDocs } from "./hooks";
export var PrDetailView = function () {
    var _a, _b, _c;
    var workspace = useWorkspace();
    var project = useProject();
    var _d = usePrDetail(), prData = _d.data, isLoading = _d.isLoading;
    var navigate = useNavigate();
    var skipPrDocsMutation = useSkipPrDocs();
    var confirmAllPrDocsMutation = useConfirmAllPrDocs();
    var draftPrDocsMutation = useDraftPrDocs();
    var _e = useSearchParams(), searchParams = _e[0], setSearchParams = _e[1];
    var shouldSkipDocs = searchParams.get("shouldSkipDocs") === "true";
    useEffect(function () {
        if (shouldSkipDocs && prData) {
            skipPrDocsMutation.mutate({ prMetadataId: prData.id });
            var newSearchParams = new URLSearchParams(searchParams);
            newSearchParams.delete("shouldSkipDocs");
            setSearchParams(newSearchParams);
        }
    }, [shouldSkipDocs, skipPrDocsMutation, prData, searchParams, setSearchParams]);
    if (isLoading) {
        return (_jsx("div", { className: "flex h-full items-center justify-center", children: _jsx(LoadingSpinner, { size: "lg" }) }));
    }
    if (!prData || !prData.repo) {
        return (_jsxs("div", { className: "flex h-full flex-col items-center justify-center gap-y-4", children: [_jsx("p", { className: "text-gray-600", children: "Pull request not found" }), _jsx(Button, { onClick: function () { return navigate(-1); }, children: "Go Back" })] }));
    }
    var documentDrafts = prData.document_pr_drafts;
    var iconForStatus = (_a = {},
        _a[PrStatus.OPEN] = _jsx(GitPullRequestArrowIcon, { className: "size-4" }),
        _a[PrStatus.MERGED] = _jsx(GitMergeIcon, { className: "size-4" }),
        _a[PrStatus.CLOSED] = _jsx(GitPullRequestClosedIcon, { className: "size-4" }),
        _a);
    var descriptionForPrDocsStatus = (_b = {},
        _b[PrDocsStatus.DRAFT] = "Docs are being drafted for this pull request. Confirm or skip each doc's draft and they will be published automatically when the PR is merged.",
        _b[PrDocsStatus.PUBLISHED] = "Docs were generated and published for this pull request",
        _b[PrDocsStatus.SKIPPED] = "Docs are skipped for this pull request",
        _b);
    var buttonsForPrDocsStatus = (_c = {},
        _c[PrDocsStatus.DRAFT] = (_jsxs(_Fragment, { children: [_jsxs(Button, { variant: "outline", onClick: function () { return skipPrDocsMutation.mutate({ prMetadataId: prData.id }); }, children: [_jsx(XIcon, { className: "size-5" }), "Skip docs for this PR"] }), _jsxs(Button, { onClick: function () { return confirmAllPrDocsMutation.mutate({ prMetadataId: prData.id }); }, children: [_jsx(BookCheckIcon, { className: "size-5" }), "Confirm all"] })] })),
        _c[PrDocsStatus.PUBLISHED] = null,
        _c[PrDocsStatus.SKIPPED] = (_jsxs(Button, { onClick: function () { return draftPrDocsMutation.mutate({ prMetadataId: prData.id }); }, children: [_jsx(BookDashedIcon, { className: "size-5" }), "Unskip docs for this PR"] })),
        _c);
    return (_jsxs("div", { className: "w-full px-16 pb-8 pt-24", children: [_jsxs("div", { className: "mb-6 flex flex-col gap-2", children: [_jsxs("div", { className: "flex flex-row justify-between gap-2", children: [_jsxs("div", { className: "flex flex-col gap-1", children: [_jsx("h1", { className: "text-2xl font-semibold", children: "Docs Drafted for Pull Request" }), _jsxs("a", { className: "flex flex-row items-center gap-1 text-accent hover:underline active:opacity-80", href: makeGithubPrUrl(prData.repo.owner, prData.repo.name, prData.pr_number), children: [iconForStatus[prData.pr_status], _jsxs("span", { children: [prData.repo.owner, "/", prData.repo.name, " #", prData.pr_number] }), _jsx(ExternalLinkIcon, { className: "size-4" })] })] }), _jsx("div", { className: "flex flex-row items-center gap-2 self-start", children: buttonsForPrDocsStatus[prData.docs_status] })] }), _jsx("p", { className: "text-sm text-secondary", children: descriptionForPrDocsStatus[prData.docs_status] })] }), documentDrafts.length === 0 ? (_jsx("div", { className: "rounded-lg border border-gray-200 p-8 text-center", children: _jsx("p", { className: "text-secondary", children: "No document drafts found in this pull request" }) })) : (_jsx("div", { className: cn("flex flex-col gap-4", prData.docs_status === PrDocsStatus.SKIPPED && "opacity-50"), children: documentDrafts.map(function (draft) { return (_jsx(Link, { to: makeDocPath({
                        workspaceSlug: workspace.slug,
                        projectSlug: project.slug,
                        documentId: draft.document.id,
                    }), children: _jsx(DocumentCard, { documentDraft: draft, disableButtons: prData.docs_status !== PrDocsStatus.DRAFT }) }, draft.id)); }) }))] }));
};
