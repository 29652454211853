export var makePublicDocPath = function (params) {
    return params.projectSlug
        ? "/pages/".concat(params.workspaceSlug, "/").concat(params.projectSlug, "/doc/").concat(params.documentId)
        : "/pages/".concat(params.workspaceSlug, "/doc/").concat(params.documentId);
};
export var makePublicDocUrl = function (baseUrl, params) {
    return "".concat(baseUrl).concat(makePublicDocPath(params));
};
export var makeDocPath = function (params) {
    return params.projectSlug
        ? "/workspaces/".concat(params.workspaceSlug, "/projects/").concat(params.projectSlug, "/docs/").concat(params.documentId)
        : "/workspaces/".concat(params.workspaceSlug, "/docs/").concat(params.documentId);
};
export var makeDocUrl = function (baseUrl, params) {
    return "".concat(baseUrl).concat(makeDocPath(params));
};
export var makePrDraftPath = function (params) {
    return "/workspaces/".concat(params.workspaceSlug, "/projects/").concat(params.projectSlug, "/prs/").concat(params.prMetadataId);
};
export var makePrDraftUrl = function (baseUrl, params) {
    return "".concat(baseUrl).concat(makePrDraftPath(params));
};
export var makeSkipDocsUrl = function (baseUrl, params) {
    return "".concat(baseUrl).concat(makePrDraftPath(params), "?shouldSkipDocs=true");
};
