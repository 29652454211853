import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PrDraftDocumentStatus } from "@cloudy/utils/common";
import { BookDashedIcon, FileCheckIcon, XIcon } from "lucide-react";
export var DraftingTitle = function (_a) {
    var _b;
    var documentDraft = _a.documentDraft;
    var status = documentDraft.status;
    var iconStates = (_b = {},
        _b[PrDraftDocumentStatus.PUBLISHED] = _jsx(FileCheckIcon, { className: "size-4 text-accent" }),
        _b[PrDraftDocumentStatus.DRAFT] = _jsx(BookDashedIcon, { className: "size-4 text-secondary" }),
        _b[PrDraftDocumentStatus.CONFIRMED] = _jsx(FileCheckIcon, { className: "size-4 text-accent" }),
        _b[PrDraftDocumentStatus.SKIPPED] = _jsx(XIcon, { className: "size-4 text-secondary" }),
        _b);
    return (_jsxs("div", { className: "flex flex-1 flex-row items-start gap-1", children: [_jsx("div", { className: "shrink-0 pt-0.5", children: iconStates[status] }), _jsx("span", { className: "flex-1 truncate text-sm font-medium text-secondary", children: documentDraft === null || documentDraft === void 0 ? void 0 : documentDraft.path })] }));
};
