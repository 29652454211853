import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ellipsizeText } from "@cloudy/utils/common";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Navigate, useParams } from "react-router-dom";
import { MainLayout } from "../../components/MainLayout";
import { useWorkspace } from "../../stores/workspace";
import { makeProjectHomeUrl } from "../../utils/projects";
import { makeHeadTitle } from "../../utils/strings";
import { useProject } from "../projects/ProjectContext";
import { DocumentContext } from "./DocumentContext";
import { DocumentLoadingPlaceholderWithPadding } from "./DocumentLoadingPlaceholder";
import { LatestDocumentVersionContext, useLatestDocumentVersion } from "./LatestDocumentVersionContext";
import { PublishedDocumentView } from "./PublishedDocumentView";
import { EditorErrorBoundary } from "./editor/EditorErrorBoundary";
import { EditorView } from "./editor/EditorView";
import { useThought, useThoughtChannelListeners } from "./editor/hooks";
var DocumentDetailInner = function (_a) {
    var documentId = _a.documentId;
    var workspace = useWorkspace();
    var project = useProject();
    var _b = useThought(documentId), document = _b.data, isLoading = _b.isLoading;
    var _c = useLatestDocumentVersion(documentId), latestDocumentVersion = _c.data, isLatestDocumentVersionLoading = _c.isLoading;
    useThoughtChannelListeners(documentId);
    var _d = useState(false), isEditMode = _d[0], setIsEditMode = _d[1];
    var _e = useState(false), isReady = _e[0], setIsReady = _e[1];
    var headTitle = (document === null || document === void 0 ? void 0 : document.title) ? makeHeadTitle(ellipsizeText(document.title, 16)) : makeHeadTitle("New Doc");
    useEffect(function () {
        if (!isLatestDocumentVersionLoading) {
            if (!latestDocumentVersion) {
                setIsEditMode(true);
            }
            setIsReady(true);
        }
    }, [latestDocumentVersion, isLatestDocumentVersionLoading]);
    if (!isLoading && !document) {
        if (project) {
            return _jsx(Navigate, { to: makeProjectHomeUrl(workspace.slug, project.slug) });
        }
        return _jsx(Navigate, { to: "/" });
    }
    return (_jsx(DocumentContext.Provider, { value: { documentId: documentId, isEditMode: isEditMode, setIsEditMode: setIsEditMode }, children: _jsx(LatestDocumentVersionContext.Provider, { value: { latestDocumentVersion: latestDocumentVersion }, children: _jsx(EditorErrorBoundary, { children: _jsxs(MainLayout, { className: "no-scrollbar relative flex h-full w-screen flex-col overflow-hidden px-0 md:w-full md:px-0 lg:px-0", children: [_jsx(Helmet, { children: _jsx("title", { children: headTitle }) }), isLoading || isLatestDocumentVersionLoading || !isReady ? (_jsx(DocumentLoadingPlaceholderWithPadding, {})) : isEditMode ? (_jsx(EditorView, { thought: document })) : (_jsx(PublishedDocumentView, {}))] }) }) }) }));
};
export var DocumentDetailView = function () {
    var documentId = useParams().documentId;
    return _jsx(DocumentDetailInner, { documentId: documentId }, documentId);
};
