import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Markdown from "react-markdown";
import { DraftingButtons } from "src/components/prDrafts/DraftingButtons";
import { DraftingTitle } from "src/components/prDrafts/DraftingTitle";
import { cn } from "src/utils";
export var DocumentCard = function (_a) {
    var _b, _c;
    var documentDraft = _a.documentDraft, disableButtons = _a.disableButtons;
    return (_jsxs("button", { className: cn("relative flex h-[200px] flex-col gap-2 overflow-hidden rounded-lg border border-border p-4 text-left transition-all hover:border-border hover:bg-card"), children: [_jsxs("div", { className: "flex w-full flex-row items-start justify-between gap-2", children: [_jsxs("div", { className: "flex flex-1 flex-col gap-1", children: [_jsx(DraftingTitle, { documentDraft: documentDraft }), _jsx("h3", { className: "mb-2 font-medium", children: (_b = documentDraft.document) === null || _b === void 0 ? void 0 : _b.title })] }), !disableButtons && _jsx(DraftingButtons, { documentDraft: documentDraft })] }), _jsx("p", { className: "tiptap relative text-tertiary", children: _jsx(Markdown, { children: (_c = documentDraft.document) === null || _c === void 0 ? void 0 : _c.content_md }) }), _jsx("div", { className: "absolute bottom-0 left-0 right-0 h-24 bg-gradient-to-t from-background to-transparent" })] }));
};
