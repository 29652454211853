import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Hotkey } from "@cloudy/ui";
import { PanelLeftOpenIcon, XIcon } from "lucide-react";
import { useContext } from "react";
import { Button } from "src/components/Button";
import { useBreakpoint } from "src/utils/tailwind";
import { useSidebarContext } from "src/views/navigation/SidebarProvider";
import { ThoughtContext } from "../thoughtContext";
import { ChatSection } from "./ChatSection";
export var ChatSectionView = function () {
    var isMd = useBreakpoint("md");
    var hideAiEditor = useContext(ThoughtContext).hideAiEditor;
    var setIsSidebarCollapsed = useSidebarContext().setIsSidebarCollapsed;
    return (_jsxs("div", { className: "absolute right-0 top-0 flex h-full w-full shrink-0 flex-col overflow-hidden border-r border-border md:w-[33vw]", children: [_jsxs("div", { className: "flex flex-row items-center justify-end px-4 py-3 md:justify-between", children: [_jsx(Button, { onClick: function () { return setIsSidebarCollapsed(false); }, variant: "ghost", size: "icon-sm", className: "hidden md:flex", children: _jsx(PanelLeftOpenIcon, { className: "size-5" }) }), _jsxs(Button, { onClick: function () { return hideAiEditor(); }, variant: "outline", size: "sm", children: [isMd ? _jsx(Hotkey, { keys: ["esc"] }) : _jsx(XIcon, { className: "size-4" }), _jsx("span", { children: "Close chat" })] })] }), _jsx(ChatSection, {})] }));
};
