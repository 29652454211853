import { ellipsizeText } from "@cloudy/utils/common";
export var makeThoughtUrl = function (wsSlug, thoughtId) {
    return "/workspaces/".concat(wsSlug, "/thoughts/").concat(thoughtId);
};
export var makeProjectDocUrl = function (wsSlug, projectSlug, docId) {
    return "/workspaces/".concat(wsSlug, "/projects/").concat(projectSlug, "/docs/").concat(docId);
};
export var makeDocUrl = function (components) {
    if (components.projectSlug) {
        return makeProjectDocUrl(components.workspaceSlug, components.projectSlug, components.docId);
    }
    return makeThoughtUrl(components.workspaceSlug, components.docId);
};
export var makeThoughtLabel = function (thought) {
    return ellipsizeText(thought.title || thought.content_plaintext || thought.content_md || "Untitled", 36);
};
