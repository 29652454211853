var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { PrDocsStatus, PrDraftDocumentStatus, handleSupabaseError } from "@cloudy/utils/common";
import { useMutation } from "@tanstack/react-query";
import { apiClient } from "src/api/client";
import { queryClient } from "src/api/queryClient";
import { prQueryKeys, thoughtQueryKeys } from "src/api/queryKeys";
import { supabase } from "src/clients/supabase";
export var useSyncPrComment = function () {
    return useMutation({
        mutationFn: function (params) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, apiClient.post("/api/integrations/github/pr-docs/sync-comment", { prMetadataId: params.prMetadataId })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); },
    });
};
export var useUpdateDocumentDraft = function () {
    var syncPrCommentMutation = useSyncPrComment();
    return useMutation({
        mutationFn: function (params) { return __awaiter(void 0, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = handleSupabaseError;
                        return [4 /*yield*/, supabase
                                .from("document_pr_drafts")
                                .update(params.update)
                                .eq("document_id", params.docId)
                                .select("pr_metadata_id")
                                .single()];
                    case 1: return [2 /*return*/, _a.apply(void 0, [_b.sent()])];
                }
            });
        }); },
        onSuccess: function (data, params) {
            queryClient.invalidateQueries({ queryKey: thoughtQueryKeys.documentDraft(params.docId) });
            queryClient.invalidateQueries({ queryKey: prQueryKeys.prDetail(data === null || data === void 0 ? void 0 : data.pr_metadata_id) });
            syncPrCommentMutation.mutate({ prMetadataId: data === null || data === void 0 ? void 0 : data.pr_metadata_id });
        },
    });
};
export var useConvertBackToDraft = function () {
    var updateDocumentDraftMutation = useUpdateDocumentDraft();
    var syncPrCommentMutation = useSyncPrComment();
    return useMutation({
        mutationFn: function (params) { return __awaiter(void 0, void 0, void 0, function () {
            var _a, _b, _c, _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _b = (_a = Promise).all;
                        _c = [updateDocumentDraftMutation.mutateAsync({
                                docId: params.docId,
                                update: { status: PrDraftDocumentStatus.DRAFT },
                            })];
                        _d = handleSupabaseError;
                        return [4 /*yield*/, supabase
                                .from("pull_request_metadata")
                                .update({ docs_status: PrDocsStatus.DRAFT })
                                .eq("id", params.prMetadataId)];
                    case 1: return [4 /*yield*/, _b.apply(_a, [_c.concat([
                                _d.apply(void 0, [_e.sent()])
                            ])])];
                    case 2: return [2 /*return*/, _e.sent()];
                }
            });
        }); },
        onSuccess: function (_, params) {
            queryClient.invalidateQueries({ queryKey: prQueryKeys.prDetail(params.prMetadataId) });
            queryClient.invalidateQueries({ queryKey: thoughtQueryKeys.documentDraft(params.docId) });
            syncPrCommentMutation.mutate({ prMetadataId: params.prMetadataId });
        },
    });
};
export var useDeletePrDrafts = function () {
    var syncPrCommentMutation = useSyncPrComment();
    return useMutation({
        mutationFn: function (params) { return __awaiter(void 0, void 0, void 0, function () {
            var drafts, _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = handleSupabaseError;
                        return [4 /*yield*/, supabase
                                .from("document_pr_drafts")
                                .select("document_id, status")
                                .eq("pr_metadata_id", params.prMetadataId)];
                    case 1:
                        drafts = _a.apply(void 0, [_c.sent()]);
                        return [4 /*yield*/, Promise.all(drafts.map(function (draft) { return __awaiter(void 0, void 0, void 0, function () {
                                var _a;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            if (!(draft.status !== PrDraftDocumentStatus.PUBLISHED && draft.document_id)) return [3 /*break*/, 2];
                                            // Delete the thought first, if the document is not published
                                            _a = handleSupabaseError;
                                            return [4 /*yield*/, supabase.from("thoughts").delete().eq("id", draft.document_id)];
                                        case 1:
                                            // Delete the thought first, if the document is not published
                                            _a.apply(void 0, [_b.sent()]);
                                            _b.label = 2;
                                        case 2: return [2 /*return*/];
                                    }
                                });
                            }); }))];
                    case 2:
                        _c.sent();
                        _b = handleSupabaseError;
                        return [4 /*yield*/, supabase.from("pull_request_metadata").delete().eq("id", params.prMetadataId)];
                    case 3: return [2 /*return*/, _b.apply(void 0, [_c.sent()])];
                }
            });
        }); },
        onSuccess: function (_, params) {
            queryClient.invalidateQueries({ queryKey: prQueryKeys.prs() });
            queryClient.invalidateQueries({ queryKey: prQueryKeys.prDetail(params.prMetadataId) });
            syncPrCommentMutation.mutate({ prMetadataId: params.prMetadataId });
        },
    });
};
