var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PrStatus, handleSupabaseError, makePrDraftPath } from "@cloudy/utils/common";
import { useQuery } from "@tanstack/react-query";
import { GitMergeIcon, GitPullRequestArrowIcon, GitPullRequestClosedIcon, MoreHorizontalIcon, TrashIcon } from "lucide-react";
import { Link } from "react-router-dom";
import { prQueryKeys } from "src/api/queryKeys";
import { supabase } from "src/clients/supabase";
import { Button } from "src/components/Button";
import { Dropdown, DropdownItem } from "src/components/Dropdown";
import { HelpTooltip } from "src/components/HelpTooltip";
import { useWorkspace } from "src/stores/workspace";
import { useDeletePrDrafts } from "src/utils/prDrafts";
import { useProject } from "../projects/ProjectContext";
import { CategoryHeader } from "./SidebarCategoryHeader";
export var useOpenPrs = function () {
    var project = useProject();
    return useQuery({
        queryKey: prQueryKeys.prs(),
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = handleSupabaseError;
                        return [4 /*yield*/, supabase
                                .from("pull_request_metadata")
                                .select("id, pr_number, pr_status, repo:repository_connections(owner,name), document_pr_drafts(*, document:thoughts(id, title, content_md))")
                                .eq("project_id", project.id)];
                    case 1: return [2 /*return*/, _a.apply(void 0, [_b.sent()])];
                }
            });
        }); },
        enabled: Boolean(project),
    });
};
var PrListItem = function (_a) {
    var _b;
    var pr = _a.pr, workspace = _a.workspace, project = _a.project;
    var deletePrDraftsMutation = useDeletePrDrafts();
    var iconForStatus = (_b = {},
        _b[PrStatus.OPEN] = _jsx(GitPullRequestArrowIcon, { className: "size-4" }),
        _b[PrStatus.MERGED] = _jsx(GitMergeIcon, { className: "size-4" }),
        _b[PrStatus.CLOSED] = _jsx(GitPullRequestClosedIcon, { className: "size-4" }),
        _b);
    return (_jsx(Link, { to: makePrDraftPath({
            workspaceSlug: workspace === null || workspace === void 0 ? void 0 : workspace.slug,
            projectSlug: project === null || project === void 0 ? void 0 : project.slug,
            prMetadataId: pr.id,
        }), children: _jsxs("div", { className: "group/item flex w-full items-center gap-1 rounded px-2 py-1 text-sm hover:bg-card", children: [iconForStatus[pr.pr_status], _jsx("span", { className: "flex-1 truncate", children: "".concat(pr.repo.owner, "/").concat(pr.repo.name, " #").concat(pr.pr_number) }), _jsx(Dropdown, { trigger: _jsx("div", { children: _jsx(Button, { variant: "ghost", size: "icon-xs-overflow", className: "hidden size-5 shrink-0 rounded-sm hover:bg-border group-hover/item:flex", children: _jsx(MoreHorizontalIcon, { className: "size-4" }) }) }), children: _jsxs(DropdownItem, { onSelect: function () { return deletePrDraftsMutation.mutate({ prMetadataId: pr.id }); }, children: [_jsx(TrashIcon, { className: "size-4" }), _jsx("span", { children: "Delete docs for PR" })] }) })] }) }, pr.id));
};
export var PendingPullRequests = function () {
    var _a;
    var _b;
    var workspace = useWorkspace();
    var project = useProject();
    var data = useOpenPrs().data;
    if (!project) {
        return null;
    }
    var prsByStatus = (_b = data === null || data === void 0 ? void 0 : data.reduce(function (acc, pr) {
        var status = pr.pr_status;
        if (!acc[status]) {
            acc[status] = [];
        }
        acc[status].push(pr);
        return acc;
    }, {})) !== null && _b !== void 0 ? _b : {};
    var orderedStatuses = [PrStatus.OPEN, PrStatus.MERGED, PrStatus.CLOSED];
    var statusLabels = (_a = {},
        _a[PrStatus.OPEN] = "Open",
        _a[PrStatus.MERGED] = "Merged",
        _a[PrStatus.CLOSED] = "Closed",
        _a);
    var isEmpty = Object.values(prsByStatus).every(function (prs) { return !prs || prs.length === 0; });
    return (_jsxs("div", { className: "flex w-full flex-col gap-2", children: [_jsx(CategoryHeader, { title: "Pull Requests", children: _jsx(HelpTooltip, { content: "Cloudy will automatically create docs for your pull requests." }) }), _jsx("div", { className: "flex flex-col gap-2", children: isEmpty ? (_jsx("div", { className: "rounded border border-dashed border-border p-2 text-xs text-tertiary", children: "Your pull requests will show up here once you create them." })) : (orderedStatuses.map(function (status) {
                    var _a;
                    return prsByStatus[status] &&
                        prsByStatus[status].length > 0 && (_jsxs("div", { className: "flex flex-col gap-1", children: [_jsx("div", { className: "px-2 text-xs font-medium text-tertiary", children: statusLabels[status] }), (_a = prsByStatus[status]) === null || _a === void 0 ? void 0 : _a.map(function (pr) { return (_jsx(PrListItem, { pr: pr, workspace: workspace, project: project }, pr.id)); })] }, status));
                })) })] }));
};
