import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Hotkey } from "@cloudy/ui";
import { MoreHorizontalIcon, XIcon } from "lucide-react";
import { useContext, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { Button } from "src/components/Button";
import { Dropdown, DropdownItem } from "src/components/Dropdown";
import { cn } from "src/utils";
import { useThought, useToggleDisableTitleSuggestions } from "./hooks";
import { AiGenerationContext, ThoughtContext } from "./thoughtContext";
export var TitleArea = function () {
    var _a = useContext(ThoughtContext), thoughtId = _a.thoughtId, isAiWriting = _a.isAiWriting, title = _a.title, setTitle = _a.setTitle;
    var isGenerating = useContext(AiGenerationContext).isGenerating;
    var thought = useThought(thoughtId).data;
    var _b = useState(false), isFocused = _b[0], setIsFocused = _b[1];
    var toggleDisableTitleSuggestionsMutation = useToggleDisableTitleSuggestions();
    var handleAcceptTitleSuggestion = function () {
        if (thought === null || thought === void 0 ? void 0 : thought.title_suggestion) {
            setTitle(thought.title_suggestion);
        }
    };
    return (_jsxs("div", { className: cn("relative flex flex-col gap-3 pb-4 md:pl-8", (isGenerating || isAiWriting) && "animate-pulse"), children: [_jsxs("div", { className: cn("flex-start flex", isGenerating && "opacity-0"), children: [_jsx(TextareaAutosize, { className: "no-scrollbar w-full resize-none appearance-none border-none bg-transparent text-2xl font-bold leading-8 outline-none md:text-3xl md:leading-10", contentEditable: true, placeholder: (thought === null || thought === void 0 ? void 0 : thought.title_suggestion) || "Untitled", value: title || "", onFocus: function () { return setIsFocused(true); }, onBlur: function () { return setIsFocused(false); }, onChange: function (e) {
                            setTitle(e.target.value);
                        }, onKeyDown: function (e) {
                            if (e.key === "Tab") {
                                handleAcceptTitleSuggestion();
                                e.preventDefault();
                                e.stopPropagation();
                            }
                        }, suppressContentEditableWarning: true }), (thought === null || thought === void 0 ? void 0 : thought.title_suggestion) && !title && (_jsxs("div", { className: "mt-1.5 flex gap-1", children: [_jsxs(Button, { size: "sm", variant: "outline", onClick: handleAcceptTitleSuggestion, children: [isFocused && _jsx(Hotkey, { keys: ["tab"] }), _jsx("span", { children: "Accept" })] }), _jsx(Dropdown, { trigger: _jsx(Button, { size: "icon-sm", variant: "outline", children: _jsx(MoreHorizontalIcon, { className: "size-4" }) }), children: _jsxs(DropdownItem, { onSelect: function () {
                                        return toggleDisableTitleSuggestionsMutation.mutate({ thoughtId: thoughtId, disableTitleSuggestions: true });
                                    }, children: [_jsx(XIcon, { className: "size-4" }), _jsx("span", { children: "Disable title suggestions for this doc" })] }) })] }))] }), isGenerating && _jsx("div", { className: "absolute left-8 top-0 h-10 w-1/2 animate-pulse rounded bg-card md:w-1/3" })] }));
};
