var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { fixOneToOne } from "./supabase";
import { handleSupabaseError } from "./supabase";
import { AccessStrategies } from "./thought";
export var FolderAccessStrategies;
(function (FolderAccessStrategies) {
    FolderAccessStrategies["PUBLIC"] = "public";
    FolderAccessStrategies["WORKSPACE"] = "workspace";
})(FolderAccessStrategies || (FolderAccessStrategies = {}));
export var getRootFolder = function (_a, supabase_1) { return __awaiter(void 0, [_a, supabase_1], void 0, function (_b, supabase) {
    var rootFolderQuery, _c;
    var workspaceId = _b.workspaceId, projectId = _b.projectId;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                rootFolderQuery = supabase
                    .from("folders")
                    .select("id")
                    .eq("is_root", true);
                if (projectId) {
                    rootFolderQuery = rootFolderQuery.eq("project_id", projectId);
                }
                else {
                    rootFolderQuery = rootFolderQuery
                        .eq("workspace_id", workspaceId)
                        .is("project_id", null);
                }
                _c = handleSupabaseError;
                return [4 /*yield*/, rootFolderQuery.maybeSingle()];
            case 1: return [2 /*return*/, _c.apply(void 0, [_d.sent()])];
        }
    });
}); };
export var createRootFolder = function (_a, supabase_1) { return __awaiter(void 0, [_a, supabase_1], void 0, function (_b, supabase) {
    var _c;
    var workspaceId = _b.workspaceId, projectId = _b.projectId;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _c = handleSupabaseError;
                return [4 /*yield*/, supabase
                        .from("folders")
                        .insert({
                        project_id: projectId !== null && projectId !== void 0 ? projectId : null,
                        name: "<ROOT>",
                        is_root: true,
                        workspace_id: workspaceId,
                        access_strategy: FolderAccessStrategies.PUBLIC,
                    })
                        .select()
                        .single()];
            case 1: return [2 /*return*/, _c.apply(void 0, [_d.sent()])];
        }
    });
}); };
export var getFolderBranch = function (folderId, allFolders) {
    var folder = allFolders.find(function (f) { return f.id === folderId; });
    if (!folder) {
        return [];
    }
    return __spreadArray([
        folder
    ], (folder.parentId
        ? getFolderBranch(folder.parentId, allFolders)
        : []), true);
};
export var getLibraryItems = function (_a, supabase_1) { return __awaiter(void 0, [_a, supabase_1], void 0, function (_b, supabase) {
    var recentDocsQuery, docsQuery, foldersQuery, recentDocs, _c, _d, docs, _e, rootFolder, folders, _f, getFolderChildren, rootItems, sharedDocs, privateDocs, workspaceDocs, items;
    var workspaceId = _b.workspaceId, projectId = _b.projectId, userId = _b.userId, publishedOnly = _b.publishedOnly, noEmptyFolders = _b.noEmptyFolders, documentExtension = _b.documentExtension, includePrDrafts = _b.includePrDrafts;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                recentDocsQuery = supabase
                    .from("thoughts")
                    .select("\n\t\t\t\t\tid, \n\t\t\t\t\taccess_strategy,\n\t\t\t\t\tauthor_id,\n\t\t\t\t\ttitle,\n\t\t\t\t\tupdated_at,\n\t\t\t\t\tlatest_version:document_versions!latest_version_id(\n\t\t\t\t\t\tid,\n\t\t\t\t\t\ttitle,\n\t\t\t\t\t\tcreated_at\n\t\t\t\t\t),\n\t\t\t\t\tdocument_pr_drafts(\n\t\t\t\t\t\tid\n\t\t\t\t\t)\n\t\t\t\t")
                    .eq("workspace_id", workspaceId)
                    .is("folder_id", null);
                docsQuery = supabase
                    .from("thoughts")
                    .select("\n\t\t\t\t\tid, \n\t\t\t\t\ttitle, \n\t\t\t\t\tindex, \n\t\t\t\t\tfolder:folders!inner(id, is_root), \n\t\t\t\t\taccess_strategy,\n\t\t\t\t\tlatest_version:document_versions!latest_version_id(\n\t\t\t\t\t\tid,\n\t\t\t\t\t\ttitle,\n\t\t\t\t\t\tcreated_at\n\t\t\t\t\t)\n\t\t\t\t")
                    .eq("workspace_id", workspaceId)
                    .not("folder_id", "is", null);
                foldersQuery = supabase
                    .from("folders")
                    .select("id, name, parent_id, is_root, index")
                    .eq("workspace_id", workspaceId)
                    .eq("is_root", false);
                if (projectId) {
                    recentDocsQuery = recentDocsQuery.eq("project_id", projectId);
                    docsQuery = docsQuery.eq("project_id", projectId);
                    foldersQuery = foldersQuery.eq("project_id", projectId);
                }
                else {
                    recentDocsQuery = recentDocsQuery.is("project_id", null);
                    docsQuery = docsQuery.is("project_id", null);
                    foldersQuery = foldersQuery.is("project_id", null);
                }
                if (publishedOnly) {
                    recentDocsQuery = recentDocsQuery.not("latest_version", "is", null);
                    docsQuery = docsQuery.not("latest_version", "is", null);
                }
                if (!includePrDrafts) {
                    recentDocsQuery = recentDocsQuery.is("document_pr_drafts", null);
                    // docsQuery = docsQuery.is("document_pr_drafts", null);
                }
                if (!userId) return [3 /*break*/, 2];
                _d = handleSupabaseError;
                return [4 /*yield*/, recentDocsQuery];
            case 1:
                _c = _d.apply(void 0, [_g.sent()]);
                return [3 /*break*/, 3];
            case 2:
                _c = [];
                _g.label = 3;
            case 3:
                recentDocs = _c;
                console.log("recentDocs", recentDocs);
                _e = handleSupabaseError;
                return [4 /*yield*/, docsQuery];
            case 4:
                docs = _e.apply(void 0, [_g.sent()]);
                return [4 /*yield*/, getRootFolder({ workspaceId: workspaceId, projectId: projectId }, supabase)];
            case 5:
                rootFolder = _g.sent();
                _f = handleSupabaseError;
                return [4 /*yield*/, foldersQuery];
            case 6:
                folders = _f.apply(void 0, [_g.sent()]);
                getFolderChildren = function (folderId, depth, parentPath) {
                    if (parentPath === void 0) { parentPath = ""; }
                    var parentId = folderId === rootFolder.id ? "<ROOT>" : folderId;
                    var childDocs = docs
                        .filter(function (doc) { var _a; return ((_a = doc.folder) === null || _a === void 0 ? void 0 : _a.id) === folderId; })
                        .map(function (doc) {
                        var _a, _b;
                        var name = (_b = (_a = fixOneToOne(doc.latest_version)) === null || _a === void 0 ? void 0 : _a.title) !== null && _b !== void 0 ? _b : doc.title;
                        return {
                            id: doc.id,
                            type: "document",
                            name: name,
                            depth: depth,
                            index: doc.index,
                            parentId: parentId,
                            path: "".concat(parentPath, "/").concat(name).concat(documentExtension || ""),
                            accessStrategy: doc.access_strategy,
                            isPublished: !!fixOneToOne(doc.latest_version),
                        };
                    });
                    var childFolders = folders
                        .filter(function (folder) { return folder.parent_id === folderId; })
                        .map(function (folder) {
                        return ({
                            id: folder.id,
                            type: "folder",
                            name: folder.name,
                            depth: depth,
                            index: folder.index,
                            parentId: parentId,
                            path: "".concat(parentPath, "/").concat(folder.name),
                        });
                    });
                    var combinedItems = __spreadArray(__spreadArray([], childDocs, true), childFolders, true);
                    var sortedItemsAtThisDepth = combinedItems.sort(function (a, b) { return a.index - b.index; });
                    // Get children for each folder and insert them after their parent folder
                    var results = [];
                    for (var _i = 0, sortedItemsAtThisDepth_1 = sortedItemsAtThisDepth; _i < sortedItemsAtThisDepth_1.length; _i++) {
                        var item = sortedItemsAtThisDepth_1[_i];
                        if (item.type === "folder") {
                            var folderChildren = getFolderChildren(item.id, depth + 1, item.path);
                            if (!noEmptyFolders || folderChildren.length > 0) {
                                results.push(item);
                                results.push.apply(results, folderChildren);
                            }
                        }
                        else {
                            results.push(item);
                        }
                    }
                    return results;
                };
                rootItems = rootFolder ? getFolderChildren(rootFolder.id, 0) : [];
                recentDocs.sort(function (a, b) {
                    var _a, _b;
                    var aLatestVersion = fixOneToOne(a.latest_version);
                    var bLatestVersion = fixOneToOne(b.latest_version);
                    return (new Date((_a = bLatestVersion === null || bLatestVersion === void 0 ? void 0 : bLatestVersion.created_at) !== null && _a !== void 0 ? _a : b.updated_at).getTime() -
                        new Date((_b = aLatestVersion === null || aLatestVersion === void 0 ? void 0 : aLatestVersion.created_at) !== null && _b !== void 0 ? _b : a.updated_at).getTime());
                });
                sharedDocs = recentDocs
                    .filter(function (doc) {
                    return doc.access_strategy === AccessStrategies.PRIVATE &&
                        doc.author_id !== userId;
                })
                    .map(function (doc) {
                    var _a, _b;
                    return ({
                        id: doc.id,
                        type: "document",
                        name: (_b = (_a = fixOneToOne(doc.latest_version)) === null || _a === void 0 ? void 0 : _a.title) !== null && _b !== void 0 ? _b : doc.title,
                        depth: 0,
                        parentId: null,
                        path: null,
                        category: "shared",
                        accessStrategy: doc.access_strategy,
                        isPublished: !!fixOneToOne(doc.latest_version),
                    });
                });
                privateDocs = recentDocs
                    .filter(function (doc) {
                    return doc.access_strategy === AccessStrategies.PRIVATE &&
                        doc.author_id === userId;
                })
                    .map(function (doc) {
                    var _a, _b;
                    return ({
                        id: doc.id,
                        type: "document",
                        name: (_b = (_a = fixOneToOne(doc.latest_version)) === null || _a === void 0 ? void 0 : _a.title) !== null && _b !== void 0 ? _b : doc.title,
                        depth: 0,
                        parentId: null,
                        path: null,
                        category: "private",
                        accessStrategy: doc.access_strategy,
                        isPublished: !!fixOneToOne(doc.latest_version),
                    });
                });
                workspaceDocs = recentDocs
                    .filter(function (doc) { return doc.access_strategy !== AccessStrategies.PRIVATE; })
                    .map(function (doc) {
                    var _a, _b;
                    return ({
                        id: doc.id,
                        type: "document",
                        name: (_b = (_a = fixOneToOne(doc.latest_version)) === null || _a === void 0 ? void 0 : _a.title) !== null && _b !== void 0 ? _b : doc.title,
                        depth: 0,
                        parentId: null,
                        path: null,
                        category: "workspace",
                        accessStrategy: doc.access_strategy,
                        isPublished: !!fixOneToOne(doc.latest_version),
                    });
                });
                items = __spreadArray(__spreadArray(__spreadArray(__spreadArray([], rootItems, true), sharedDocs, true), privateDocs, true), workspaceDocs, true);
                return [2 /*return*/, items];
        }
    });
}); };
