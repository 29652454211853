var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { handleSupabaseError } from "@cloudy/utils/common";
import { useIsMutating, useMutation, useQuery } from "@tanstack/react-query";
import { distance } from "fastest-levenshtein";
import posthog from "posthog-js";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { apiClient } from "src/api/client";
import { queryClient } from "src/api/queryClient";
import { collectionQueryKeys, projectQueryKeys, thoughtQueryKeys } from "src/api/queryKeys";
import { supabase } from "src/clients/supabase";
import { useUser } from "src/stores/user";
import { useWorkspace, useWorkspaceStore } from "src/stores/workspace";
import { makeDocUrl } from "src/utils/thought";
import { useProject } from "src/views/projects/ProjectContext";
import { useDocumentContext } from "../DocumentContext";
import { ThoughtContext } from "./thoughtContext";
var MINIMUM_CONTENT_LENGTH = 3;
var MINIMUM_EDIT_DISTANCE = 64;
var triggerAiUpdatesWhenChangeIsSignificant = function (thoughtId, contentMd, lastContentMd, force) { return __awaiter(void 0, void 0, void 0, function () {
    var editDistance;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!force) {
                    if (lastContentMd === contentMd) {
                        return [2 /*return*/];
                    }
                    if (contentMd.length < MINIMUM_CONTENT_LENGTH) {
                        return [2 /*return*/];
                    }
                    if (lastContentMd) {
                        editDistance = distance(contentMd, lastContentMd);
                        if (editDistance < MINIMUM_EDIT_DISTANCE) {
                            return [2 /*return*/];
                        }
                    }
                }
                return [4 /*yield*/, apiClient.post("/api/ai/update-thought", {
                        thoughtId: thoughtId,
                        force: force,
                    }, { timeout: 90000 })];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}); };
export var useEditDocument = function (documentId) {
    var workspace = useWorkspace();
    var project = useProject();
    var isMutating = Boolean(useIsMutating({ mutationKey: ["editDocument"] }));
    return useMutation({
        mutationKey: ["editDocument"],
        mutationFn: function (payload) { return __awaiter(void 0, void 0, void 0, function () {
            var titleObj, contentHtmlObj, contentJsonObj, contentMdObj, contentPlainTextObj, accessStrategyObj, newDocument, _a;
            var _b, _c, _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        if (isMutating) {
                            console.log("Return on edit thought to prevent race conditions");
                            return [2 /*return*/];
                        }
                        titleObj = {};
                        if ((payload === null || payload === void 0 ? void 0 : payload.title) !== undefined) {
                            titleObj = { title: payload.title, title_ts: payload.ts, title_suggestion: null };
                        }
                        contentHtmlObj = {};
                        if ((payload === null || payload === void 0 ? void 0 : payload.contentHtml) !== undefined) {
                            contentHtmlObj = { content: payload.contentHtml };
                        }
                        contentJsonObj = {};
                        if ((payload === null || payload === void 0 ? void 0 : payload.contentJson) !== undefined) {
                            contentJsonObj = { content_json: payload.contentJson, content_ts: payload.ts };
                        }
                        contentMdObj = {};
                        if ((payload === null || payload === void 0 ? void 0 : payload.contentMd) !== undefined) {
                            contentMdObj = { content_md: payload.contentMd };
                        }
                        contentPlainTextObj = {};
                        if ((payload === null || payload === void 0 ? void 0 : payload.contentPlainText) !== undefined) {
                            contentPlainTextObj = { content_plaintext: payload.contentPlainText };
                        }
                        accessStrategyObj = {};
                        if ((payload === null || payload === void 0 ? void 0 : payload.accessStrategy) !== undefined) {
                            accessStrategyObj = { access_strategy: payload.accessStrategy };
                        }
                        _a = handleSupabaseError;
                        return [4 /*yield*/, supabase
                                .from("thoughts")
                                .upsert(__assign(__assign(__assign(__assign(__assign(__assign({ id: documentId, workspace_id: workspace.id, project_id: (_b = project === null || project === void 0 ? void 0 : project.id) !== null && _b !== void 0 ? _b : null, updated_at: (_c = payload === null || payload === void 0 ? void 0 : payload.ts.toISOString()) !== null && _c !== void 0 ? _c : new Date().toISOString() }, titleObj), contentHtmlObj), contentJsonObj), contentMdObj), contentPlainTextObj), accessStrategyObj))
                                .select("*, collections:collection_thoughts(collection_id, collection:collections(id))")
                                .single()];
                    case 1:
                        newDocument = _a.apply(void 0, [_e.sent()]);
                        triggerAiUpdatesWhenChangeIsSignificant(newDocument.id, (_d = newDocument.content_md) !== null && _d !== void 0 ? _d : "", newDocument.last_suggestion_content_md);
                        posthog.capture("edit_thought", {
                            documentId: documentId,
                        });
                        return [2 /*return*/, newDocument];
                }
            });
        }); },
        onError: function (e) {
            console.error(e);
        },
        onSuccess: function (doc) {
            queryClient.invalidateQueries({
                queryKey: ["thoughtEmbeddings"],
            });
            setTimeout(function () {
                queryClient.invalidateQueries({
                    queryKey: ["thoughtEmbeddings"],
                });
            }, 2500);
            queryClient.invalidateQueries({
                queryKey: projectQueryKeys.library(workspace.id, project === null || project === void 0 ? void 0 : project.id),
            });
            if (doc) {
                queryClient.invalidateQueries({
                    queryKey: thoughtQueryKeys.sharedWith(doc.id),
                });
            }
        },
    });
};
export var useForceAiUpdate = function (thoughtId) {
    return useMutation({
        mutationFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var _a, contentMd, lastSuggestionContentMd, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!thoughtId) {
                            return [2 /*return*/];
                        }
                        _b = handleSupabaseError;
                        return [4 /*yield*/, supabase
                                .from("thoughts")
                                .select("contentMd:content_md, lastSuggestionContentMd:last_suggestion_content_md")
                                .eq("id", thoughtId)
                                .single()];
                    case 1:
                        _a = _b.apply(void 0, [_c.sent()]), contentMd = _a.contentMd, lastSuggestionContentMd = _a.lastSuggestionContentMd;
                        return [2 /*return*/, triggerAiUpdatesWhenChangeIsSignificant(thoughtId, contentMd !== null && contentMd !== void 0 ? contentMd : "", lastSuggestionContentMd, true)];
                }
            });
        }); },
    });
};
export var useThoughtChannelListeners = function (thoughtId) {
    useEffect(function () {
        var channel = supabase
            .channel("thought")
            .on("postgres_changes", {
            event: "*",
            schema: "public",
            table: "thoughts",
            filter: "id=eq.".concat(thoughtId),
        }, function () {
            queryClient.invalidateQueries({
                queryKey: ["thought", thoughtId],
            });
        })
            .subscribe();
        return function () {
            channel.unsubscribe();
        };
    }, [thoughtId]);
    useEffect(function () {
        var channel = supabase
            .channel("thoughtCollectionsLoad")
            .on("postgres_changes", {
            event: "*",
            schema: "public",
            table: "collection_thoughts",
            filter: "thought_id=eq.".concat(thoughtId),
        }, function () {
            queryClient.invalidateQueries({
                queryKey: ["thought", thoughtId],
            });
        })
            .subscribe();
        return function () {
            channel.unsubscribe();
        };
    }, [thoughtId]);
    useEffect(function () {
        var channel = supabase
            .channel("document_updates")
            .on("postgres_changes", {
            event: "*",
            schema: "public",
            table: "document_updates",
            filter: "document_id=eq.".concat(thoughtId),
        }, function () {
            queryClient.invalidateQueries({
                queryKey: thoughtQueryKeys.recentChanges(thoughtId),
            });
        })
            .subscribe();
        return function () {
            channel.unsubscribe();
        };
    }, [thoughtId]);
    useEffect(function () {
        var channel = supabase
            .channel("document_chat_threads")
            .on("postgres_changes", {
            event: "*",
            schema: "public",
            table: "chat_threads",
            filter: "document_id=eq.".concat(thoughtId),
        }, function () {
            queryClient.invalidateQueries({
                queryKey: thoughtQueryKeys.threadsForDoc(thoughtId),
            });
        })
            .subscribe();
        return function () {
            channel.unsubscribe();
        };
    }, [thoughtId]);
};
export var useThought = function (thoughtId) {
    return useQuery({
        queryKey: thoughtQueryKeys.thoughtDetail(thoughtId),
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var thought, _a;
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!thoughtId) {
                            return [2 /*return*/, null];
                        }
                        _a = handleSupabaseError;
                        return [4 /*yield*/, supabase
                                .from("thoughts")
                                .select("*, \n\t\t\t\t\tcollections:collection_thoughts(\n\t\t\t\t\t\tcollection_id,\n\t\t\t\t\t\tcollection:collections(\n\t\t\t\t\t\t\tid,\n\t\t\t\t\t\t\ttitle\n\t\t\t\t\t\t)\n\t\t\t\t\t)")
                                .eq("id", thoughtId)
                                .maybeSingle()];
                    case 1:
                        thought = _a.apply(void 0, [_c.sent()]);
                        return [2 /*return*/, thought
                                ? __assign(__assign({}, thought), { collections: (_b = thought.collections.flatMap(function (collection) { return (collection.collection ? [collection.collection] : []); })) !== null && _b !== void 0 ? _b : [] }) : null];
                }
            });
        }); },
        enabled: !!thoughtId,
    });
};
export var useDeleteThought = function () {
    var workspace = useWorkspace();
    return useMutation({
        mutationFn: function (thoughtId) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, supabase.from("thoughts").delete().eq("id", thoughtId)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, thoughtId];
                }
            });
        }); },
        onSuccess: function (thoughtId) {
            queryClient.invalidateQueries({
                queryKey: thoughtQueryKeys.thoughtDetail(thoughtId),
            });
            queryClient.invalidateQueries({
                queryKey: thoughtQueryKeys.workspaceSidebarLatestThoughts(workspace.id),
            });
            queryClient.invalidateQueries({
                queryKey: collectionQueryKeys.collectionDetailThoughts(),
            });
        },
    });
};
export var useToggleDisableTitleSuggestions = function () {
    return useMutation({
        mutationFn: function (payload) { return __awaiter(void 0, void 0, void 0, function () {
            var outcome, currentValue, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        outcome = !payload.disableTitleSuggestions;
                        if (!(typeof payload.disableTitleSuggestions !== "boolean")) return [3 /*break*/, 2];
                        _a = handleSupabaseError;
                        return [4 /*yield*/, supabase.from("thoughts").select("disable_title_suggestions").eq("id", payload.thoughtId).single()];
                    case 1:
                        currentValue = _a.apply(void 0, [_b.sent()]);
                        outcome = !currentValue.disable_title_suggestions;
                        _b.label = 2;
                    case 2: return [4 /*yield*/, supabase
                            .from("thoughts")
                            .update({ disable_title_suggestions: outcome, title_suggestion: null })
                            .eq("id", payload.thoughtId)];
                    case 3:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        }); },
        onSuccess: function (_, payload) {
            queryClient.invalidateQueries({
                queryKey: thoughtQueryKeys.thoughtDetail(payload.thoughtId),
            });
        },
    });
};
export var useGenerateDocument = function () {
    var _a = useContext(ThoughtContext), editor = _a.editor, setTitle = _a.setTitle;
    var _b = useState(false), hasStarted = _b[0], setHasStarted = _b[1];
    var mutation = useMutation({
        mutationFn: function (docId) { return __awaiter(void 0, void 0, void 0, function () {
            var response, reader, fullText, title, _a, done, value, chunk;
            var _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (!editor) {
                            throw new Error("Editor not found");
                        }
                        return [4 /*yield*/, fetch(apiClient.getUri({ url: "/api/ai/generate-document" }), {
                                method: "POST",
                                // @ts-ignore
                                headers: __assign(__assign({}, apiClient.defaults.headers.common), { "Content-Type": "application/json" }),
                                body: JSON.stringify({ docId: docId }),
                            })];
                    case 1:
                        response = _d.sent();
                        reader = (_b = response.body) === null || _b === void 0 ? void 0 : _b.getReader();
                        if (!reader) {
                            throw new Error("Failed to get reader from response");
                        }
                        setHasStarted(true);
                        fullText = "";
                        title = "";
                        _d.label = 2;
                    case 2:
                        if (!true) return [3 /*break*/, 4];
                        return [4 /*yield*/, reader.read()];
                    case 3:
                        _a = _d.sent(), done = _a.done, value = _a.value;
                        if (done)
                            return [3 /*break*/, 4];
                        chunk = new TextDecoder().decode(value);
                        fullText += chunk;
                        if (fullText.startsWith("```title:") && fullText.includes("\n")) {
                            title = fullText.split("\n")[0].replace("```title:", "").trim();
                            fullText = fullText.split("\n").slice(1).join("\n");
                        }
                        setTitle(title);
                        editor.commands.setContent(fullText);
                        return [3 /*break*/, 2];
                    case 4:
                        if (((_c = fullText.split("\n").at(-1)) === null || _c === void 0 ? void 0 : _c.trim()) === "```") {
                            fullText = fullText.split("\n").slice(0, -1).join("\n");
                        }
                        editor.commands.setContent(fullText);
                        setHasStarted(false);
                        return [4 /*yield*/, supabase.from("thoughts").update({ title: title, generated_at: new Date().toISOString() }).eq("id", docId)];
                    case 5:
                        _d.sent();
                        return [2 /*return*/];
                }
            });
        }); },
        onSettled: function () {
            setHasStarted(false);
        },
    });
    return __assign(__assign({}, mutation), { hasStarted: hasStarted });
};
export var useDefaultThreadId = function () {
    var thoughtId = useContext(ThoughtContext).thoughtId;
    return useQuery({
        queryKey: thoughtQueryKeys.defaultThreadId(thoughtId),
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var result, _a;
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = handleSupabaseError;
                        return [4 /*yield*/, supabase
                                .from("chat_threads")
                                .select("id")
                                .eq("document_id", thoughtId)
                                .eq("is_default", true)
                                .maybeSingle()];
                    case 1:
                        result = _a.apply(void 0, [_c.sent()]);
                        return [2 /*return*/, (_b = result === null || result === void 0 ? void 0 : result.id) !== null && _b !== void 0 ? _b : null];
                }
            });
        }); },
    });
};
export var useExistingLinkedFiles = function (docId) {
    return useQuery({
        queryKey: thoughtQueryKeys.existingLinkedFiles(docId),
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var repoReferences, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = handleSupabaseError;
                        return [4 /*yield*/, supabase
                                .from("document_repo_links")
                                .select("*, repository_connections(owner, name, default_branch)")
                                .eq("document_id", docId)];
                    case 1:
                        repoReferences = _a.apply(void 0, [_b.sent()]);
                        return [2 /*return*/, repoReferences.map(function (repoReference) {
                                var _a;
                                var branch = (_a = repoReference.branch) !== null && _a !== void 0 ? _a : repoReference.repository_connections.default_branch;
                                return __assign(__assign({}, repoReference), { repoConnectionId: repoReference.repo_connection_id, fileName: repoReference.path.split("/").pop(), repoFullName: "".concat(repoReference.repository_connections.owner, "/").concat(repoReference.repository_connections.name), branch: branch, fileUrl: "https://github.com/".concat(repoReference.repository_connections.owner, "/").concat(repoReference.repository_connections.name, "/blob/").concat(branch, "/").concat(repoReference.path) });
                            })];
                }
            });
        }); },
    });
};
export var usePublishDocumentVersion = function () {
    var user = useUser();
    var workspace = useWorkspace();
    var project = useProject();
    var documentId = useDocumentContext().documentId;
    var document = useThought(documentId).data;
    var editor = useContext(ThoughtContext).editor;
    return useMutation({
        mutationFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var result, _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!editor) {
                            throw new Error("Editor not found");
                        }
                        _a = handleSupabaseError;
                        return [4 /*yield*/, supabase
                                .from("document_versions")
                                .insert({
                                document_id: documentId,
                                published_by: user.id,
                                title: document.title,
                                content_json: editor.getJSON(),
                                content_md: editor.storage.markdown.getMarkdown(),
                                content_html: editor.getHTML(),
                            })
                                .select("id")
                                .single()];
                    case 1:
                        result = _a.apply(void 0, [_c.sent()]);
                        _b = handleSupabaseError;
                        return [4 /*yield*/, supabase.from("thoughts").update({ latest_version_id: result.id }).eq("id", documentId)];
                    case 2:
                        _b.apply(void 0, [_c.sent()]);
                        return [2 /*return*/];
                }
            });
        }); },
        onSuccess: function () {
            queryClient.invalidateQueries({
                queryKey: thoughtQueryKeys.latestPublishedVersion(documentId),
            });
            queryClient.invalidateQueries({
                queryKey: projectQueryKeys.library(workspace.id, project === null || project === void 0 ? void 0 : project.id),
            });
        },
    });
};
export var useCreateDocument = function () {
    var workspace = useWorkspaceStore(function (s) { return s.workspace; });
    var project = useProject();
    var editDocumentMutation = useEditDocument();
    var navigate = useNavigate();
    return useMutation({
        mutationFn: function (payload) { return __awaiter(void 0, void 0, void 0, function () {
            var newDocument;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!workspace) {
                            throw new Error("Workspace not found");
                        }
                        return [4 /*yield*/, editDocumentMutation.mutateAsync({
                                collectionId: payload.collectionId,
                                ts: new Date(),
                            })];
                    case 1:
                        newDocument = _a.sent();
                        return [2 /*return*/, newDocument];
                }
            });
        }); },
        onError: function (e) {
            console.error(e);
            toast.error("Failed to create document");
        },
        onSuccess: function (newDocument) {
            if (workspace && newDocument) {
                navigate(makeDocUrl({ workspaceSlug: workspace.slug, projectSlug: project === null || project === void 0 ? void 0 : project.slug, docId: newDocument.id }));
            }
        },
    });
};
