import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { PrDraftDocumentStatus, makeGithubPrUrl, makePrDraftPath } from "@cloudy/utils/common";
import { ArrowLeftIcon, ExternalLinkIcon } from "lucide-react";
import { Link } from "react-router-dom";
import { Button } from "src/components/Button";
import { DraftingButtons } from "src/components/prDrafts/DraftingButtons";
import { DraftingTitle } from "src/components/prDrafts/DraftingTitle";
import { useWorkspace } from "src/stores/workspace";
import { useProject } from "src/views/projects/ProjectContext";
import { useDocumentDraft } from "../drafting";
export var EditorDraftingCard = function () {
    var _a;
    var _b, _c;
    var workspace = useWorkspace();
    var project = useProject();
    var documentDraft = useDocumentDraft().data;
    if (!((_b = documentDraft === null || documentDraft === void 0 ? void 0 : documentDraft.pull_request_metadata) === null || _b === void 0 ? void 0 : _b.repo) || !project) {
        return null;
    }
    var status = documentDraft.status;
    var githubPrUrl = makeGithubPrUrl(documentDraft.pull_request_metadata.repo.owner, documentDraft.pull_request_metadata.repo.name, documentDraft.pull_request_metadata.pr_number);
    var prNumberContent = (_jsxs("a", { href: githubPrUrl, className: "font-medium text-accent hover:underline", children: [_jsxs("span", { children: ["#", documentDraft.pull_request_metadata.pr_number] }), _jsx(ExternalLinkIcon, { className: "mb-1 ml-1 inline-block size-4" })] }));
    var textStates = (_a = {},
        _a[PrDraftDocumentStatus.PUBLISHED] = _jsxs("p", { children: ["This doc is published and tied to pull request ", prNumberContent, "."] }),
        _a[PrDraftDocumentStatus.DRAFT] = (_jsxs("p", { children: ["This doc is a draft tied to pull request ", prNumberContent, ". Hit confirm to publish it when the pull request gets merged."] })),
        _a[PrDraftDocumentStatus.CONFIRMED] = (_jsxs("p", { children: ["This doc is confirmed to be published when pull request ", prNumberContent, "gets merged"] })),
        _a[PrDraftDocumentStatus.SKIPPED] = (_jsxs("p", { children: ["This doc is skipped and will not be published when pull request ", prNumberContent, "gets merged"] })),
        _a);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "mb-4", children: _jsx(Link, { to: makePrDraftPath({
                        workspaceSlug: workspace.slug,
                        projectSlug: project.slug,
                        prMetadataId: documentDraft.pull_request_metadata.id,
                    }), children: _jsxs(Button, { variant: "ghost", size: "sm", children: [_jsx(ArrowLeftIcon, { className: "size-4" }), _jsxs("span", { children: ["Back to draft docs for PR #", (_c = documentDraft === null || documentDraft === void 0 ? void 0 : documentDraft.pull_request_metadata) === null || _c === void 0 ? void 0 : _c.pr_number] })] }) }) }), _jsxs("div", { className: "flex flex-col gap-2 rounded-md border border-border p-4", children: [_jsx(DraftingTitle, { documentDraft: documentDraft }), _jsx("div", { children: textStates[status] }), _jsx("div", { className: "mt-2", children: _jsx(DraftingButtons, { documentDraft: documentDraft }) })] })] }));
};
