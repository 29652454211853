var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Slot } from "@radix-ui/react-slot";
import { cva } from "class-variance-authority";
import * as React from "react";
import { cn } from "src/utils";
var buttonVariants = cva("inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 group", {
    variants: {
        variant: {
            default: "bg-accent text-background dark:text-primary hover:bg-accent/80 group-hover:bg-accent/80",
            destructive: "bg-red-600 text-background dark:text-primary hover:bg-red-600/70 group-hover:bg-red-600/70",
            outline: "border border-input hover:bg-border hover:text-accent group-hover:bg-card group-hover:text-accent",
            secondary: "bg-card text-primary hover:opacity-70 group-hover:opacity-70 group-hover:text-background",
            ghost: "hover:bg-accent hover:text-background dark:hover:text-primary group-hover:bg-accent group-hover:text-background active:bg-accent active:text-background dark:active:text-primary",
            link: "text-primary underline-offset-4 hover:underline group-hover:underline",
        },
        size: {
            default: "h-10 px-4 py-2 gap-2",
            xs: "h-6 rounded px-1.5 text-xs gap-1",
            sm: "h-8 rounded-md px-3 text-xs gap-1",
            lg: "h-11 rounded-md px-6 text-base gap-2",
            icon: "size-10 text-xs",
            "icon-sm": "size-8 text-xs",
            "icon-sm-overflow": "size-8 -m-2 text-xs",
            "icon-xs": "size-6 rounded text-xs",
            "icon-xs-overflow": "size-6 rounded -m-1 text-xs",
        },
    },
    defaultVariants: {
        variant: "default",
        size: "default",
    },
});
var Button = React.forwardRef(function (_a, ref) {
    var className = _a.className, variant = _a.variant, size = _a.size, _b = _a.asChild, asChild = _b === void 0 ? false : _b, props = __rest(_a, ["className", "variant", "size", "asChild"]);
    var Comp = asChild ? Slot : "button";
    return _jsx(Comp, __assign({ className: cn(buttonVariants({ variant: variant, size: size, className: className })), ref: ref }, props));
});
Button.displayName = "Button";
export { Button, buttonVariants };
