export var PrStatus;
(function (PrStatus) {
    PrStatus["OPEN"] = "open";
    PrStatus["CLOSED"] = "closed";
    PrStatus["MERGED"] = "merged";
})(PrStatus || (PrStatus = {}));
export var PrDocsStatus;
(function (PrDocsStatus) {
    PrDocsStatus["PUBLISHED"] = "published";
    PrDocsStatus["DRAFT"] = "draft";
    PrDocsStatus["SKIPPED"] = "skipped";
})(PrDocsStatus || (PrDocsStatus = {}));
export var PrDraftDocumentStatus;
(function (PrDraftDocumentStatus) {
    PrDraftDocumentStatus["PUBLISHED"] = "published";
    PrDraftDocumentStatus["CONFIRMED"] = "confirmed";
    PrDraftDocumentStatus["DRAFT"] = "draft";
    PrDraftDocumentStatus["SKIPPED"] = "skipped";
})(PrDraftDocumentStatus || (PrDraftDocumentStatus = {}));
export var makeGithubPrUrl = function (owner, repo, prNumber) {
    return "https://github.com/".concat(owner, "/").concat(repo, "/pull/").concat(prNumber);
};
