import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SiGithub } from "@icons-pack/react-simple-icons";
import { ArrowRightIcon } from "lucide-react";
import { useWorkspaceGithubInstallations } from "src/stores/workspace";
export var ConnectGithubCard = function () {
    var _a;
    var installations = useWorkspaceGithubInstallations().data;
    return (_jsxs("a", { href: process.env.NODE_ENV === "development"
            ? "https://github.com/apps/cloudy-ai-dev/installations/new"
            : "https://github.com/apps/usecloudyai/installations/new", className: "text-medium flex h-14 w-full flex-row items-center justify-between gap-4 rounded-md border border-border px-4 text-sm hover:bg-card", children: [_jsxs("div", { className: "flex flex-row items-center gap-4", children: [_jsx(SiGithub, { className: "size-6 text-secondary" }), ((_a = installations === null || installations === void 0 ? void 0 : installations.length) !== null && _a !== void 0 ? _a : 0) > 0 ? (_jsx("span", { children: "Connect another GitHub account" })) : (_jsx("span", { children: "Install the Cloudy Github App" }))] }), _jsx(ArrowRightIcon, { className: "size-4 text-secondary" })] }));
};
