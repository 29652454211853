import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Hotkey } from "@cloudy/ui";
import { useContext } from "react";
import { Button } from "src/components/Button";
import { useBreakpoint } from "src/utils/tailwind";
import { ThoughtContext } from "./thoughtContext";
export var FooterRow = function () {
    var _a = useContext(ThoughtContext), showAiEditor = _a.showAiEditor, isShowingAiEditorMenu = _a.isShowingAiEditorMenu;
    var isMdBreakpoint = useBreakpoint("md");
    return (_jsx("div", { className: "pointer-events-none sticky bottom-0 z-10 flex w-full items-center justify-center p-4 md:justify-between", children: _jsx("div", { className: "pointer-events-auto", children: _jsx("div", { children: !isShowingAiEditorMenu && (_jsxs(Button, { size: "sm", onClick: showAiEditor, children: [isMdBreakpoint && _jsx(Hotkey, { keys: ["Command", "O"] }), _jsx("span", { children: "Chat" })] })) }) }) }));
};
