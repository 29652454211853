var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ChatRole, handleSupabaseError, makeHumanizedTime } from "@cloudy/utils/common";
import { SiGithub } from "@icons-pack/react-simple-icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import { ArrowRightIcon, ExternalLinkIcon, FileCodeIcon, MoreHorizontalIcon, TrashIcon, XIcon } from "lucide-react";
import { useContext } from "react";
import Markdown from "react-markdown";
import { Link } from "react-router-dom";
import { thoughtQueryKeys } from "src/api/queryKeys";
import { supabase } from "src/clients/supabase";
import { Button } from "src/components/Button";
import { Dropdown, DropdownItem } from "src/components/Dropdown";
import { HelpTooltip } from "src/components/HelpTooltip";
import LoadingSpinner from "src/components/LoadingSpinner";
import { LinkedFilesDropdown } from "../../navBar/LinkedFilesDropdown";
import { ThoughtContext } from "../thoughtContext";
import { ChatMessageUserHeader } from "./ChatMessageUserHeader";
import { useDeleteThread, useThreadsForDoc } from "./chat";
var useRecentChanges = function () {
    var thoughtId = useContext(ThoughtContext).thoughtId;
    return useQuery({
        queryKey: thoughtQueryKeys.recentChanges(thoughtId),
        queryFn: function () { return __awaiter(void 0, void 0, void 0, function () {
            var result, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = handleSupabaseError;
                        return [4 /*yield*/, supabase
                                .from("document_updates")
                                .select("*, repo:repository_connections(provider, owner, name), files:document_update_links(document_repo_links(*)), suggestion_threads:chat_threads(*)")
                                .eq("document_id", thoughtId)
                                .order("triggered_at", { ascending: false })];
                    case 1:
                        result = _a.apply(void 0, [_b.sent()]);
                        return [2 /*return*/, result.map(function (change) { return (__assign(__assign({}, change), { files: change.files.flatMap(function (repoFile) { return (repoFile.document_repo_links ? [repoFile.document_repo_links] : []); }) })); })];
                }
            });
        }); },
    });
};
var useDeleteDocumentUpdate = function (documentUpdateId) {
    return useMutation({
        mutationFn: function () { return __awaiter(void 0, void 0, void 0, function () { var _a; return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = handleSupabaseError;
                    return [4 /*yield*/, supabase.from("document_updates").delete().eq("id", documentUpdateId)];
                case 1: return [2 /*return*/, _a.apply(void 0, [_b.sent()])];
            }
        }); }); },
    });
};
export var ChatHomeView = function () {
    var _a = useContext(ThoughtContext), thoughtId = _a.thoughtId, setThreadId = _a.setThreadId;
    var _b = useRecentChanges(), recentChanges = _b.data, isLoadingRecentChanges = _b.isLoading;
    var _c = useThreadsForDoc(thoughtId), threadsForDoc = _c.data, isLoadingThreads = _c.isLoading;
    return (_jsxs("div", { className: "no-scrollbar flex h-full w-full flex-col gap-2 overflow-y-auto py-4", children: [_jsxs("div", { className: "flex flex-row items-center justify-between gap-1", children: [_jsxs("div", { className: "flex flex-row items-center gap-1", children: [_jsx("h3", { className: "text-lg font-semibold", children: "Recent Changes" }), _jsx(HelpTooltip, { content: "Cloudy will keep track of changes to files in your repositories that are linked to this document." })] }), _jsx(LinkedFilesDropdown, { trigger: _jsxs(Button, { variant: "ghost", size: "xs", className: "text-secondary", children: [_jsx(FileCodeIcon, { className: "size-4" }), _jsx("span", { children: "Linked Files" })] }) })] }), isLoadingRecentChanges ? (_jsx(LoadingSpinner, { size: "sm" })) : !recentChanges || (recentChanges === null || recentChanges === void 0 ? void 0 : recentChanges.length) === 0 ? (_jsx("div", { className: "flex flex-col items-center justify-center gap-y-2 py-2 text-sm text-tertiary", children: _jsx("p", { children: "No recent changes affecting this document." }) })) : (recentChanges.map(function (change) { return _jsx(DocChangeItem, { change: change, setThreadId: setThreadId }, change.id); })), _jsx("div", { className: "flex flex-row items-center justify-between", children: _jsx("h3", { className: "text-lg font-semibold", children: "Recent Threads" }) }), isLoadingThreads ? (_jsx(LoadingSpinner, { size: "sm" })) : !threadsForDoc || (threadsForDoc === null || threadsForDoc === void 0 ? void 0 : threadsForDoc.length) === 0 ? (_jsxs("div", { className: "flex flex-col items-center justify-center gap-y-2 py-2 text-sm text-tertiary", children: [_jsx("p", { children: "No chat threads yet" }), _jsx("p", { children: "Start a new conversation below" })] })) : (threadsForDoc.map(function (thread) { return _jsx(ThreadButton, { thread: thread, setThreadId: setThreadId }, thread.id); }))] }));
};
var DocChangeItem = function (_a) {
    var change = _a.change, setThreadId = _a.setThreadId;
    var repo = change.repo;
    var linkUrl = repo ? "https://github.com/".concat(repo.owner, "/").concat(repo.name, "/commit/").concat(change.commit_sha) : "";
    var deleteDocumentUpdateMutation = useDeleteDocumentUpdate(change.id);
    return (_jsxs("div", { className: "flex w-full flex-col gap-1 rounded-lg border border-border p-4", children: [_jsxs("div", { className: "flex w-full flex-row items-start justify-between", children: [_jsxs("div", { className: "flex flex-col items-start", children: [_jsxs("div", { className: "flex flex-row items-center gap-x-1", children: [_jsx(Link, { to: linkUrl, target: "_blank", children: _jsxs("div", { className: "flex flex-row items-baseline gap-x-1 text-secondary hover:text-accent hover:underline", children: [_jsx(SiGithub, { className: "size-3" }), _jsxs("div", { className: "font-mono text-xs", children: [repo ? "".concat(repo.owner, "/").concat(repo.name, " ") : " ", change.commit_sha.slice(0, 6)] }), _jsx(ExternalLinkIcon, { className: "size-3 translate-y-[0.06rem] stroke-2" })] }) }), _jsx("span", { className: "text-sm text-tertiary", children: "\u2022" }), _jsx("div", { className: "text-xs text-secondary", children: makeHumanizedTime(change.triggered_at) })] }), _jsxs("div", { className: "flex flex-row items-center gap-x-1", children: [_jsx("span", { className: "text-xs text-secondary", children: "Changed files:" }), change.files.map(function (file) { return (_jsx("div", { className: "text-xs text-secondary", children: file.path.split("/").pop() })); })] })] }), _jsx("div", { className: "flex flex-row items-center gap-x-2", children: change.generation_completed_at ? (_jsxs(Button, { variant: "outline", size: "xs", onClick: function () { return deleteDocumentUpdateMutation.mutate(); }, children: [_jsx(XIcon, { className: "size-3.5" }), _jsx("span", { children: "Dismiss" })] })) : (_jsx(LoadingSpinner, { size: "xs" })) })] }), change.suggestion_threads && change.suggestion_threads.length > 0 ? (_jsxs(Button, { className: "self-start text-accent", variant: "outline", size: "xs", onClick: function () {
                    setThreadId(change.suggestion_threads[0].id);
                }, children: [_jsx("span", { children: "See suggested changes" }), _jsx(ArrowRightIcon, { className: "size-3.5" })] })) : change.generation_completed_at ? (_jsx("div", { className: "text-xs text-tertiary", children: "No changes are needed." })) : (_jsx("div", { className: "text-xs text-tertiary", children: "Generating suggested changes..." }))] }));
};
var ThreadButton = function (_a) {
    var thread = _a.thread, setThreadId = _a.setThreadId;
    var deleteThreadMutation = useDeleteThread(thread.id);
    var firstMessage = thread.first_message[0];
    return (_jsxs("button", { onClick: function (e) {
            // Only set thread ID if not clicking dropdown item
            if (!e.target.closest(".dropdown-item")) {
                setThreadId(thread.id);
            }
        }, className: "flex w-full flex-row items-start justify-between gap-x-2 rounded-lg border border-border p-4 text-left hover:bg-card", children: [firstMessage && (_jsxs("div", { className: "flex flex-1 flex-col pt-0.5", children: [firstMessage.role === ChatRole.User && firstMessage.user_id && (_jsx(ChatMessageUserHeader, { userId: firstMessage.user_id })), _jsx("div", { className: "pointer-events-none line-clamp-2 text-sm", children: _jsx(Markdown, { children: firstMessage.content || "Empty thread" }) })] })), _jsxs("div", { className: "flex flex-row items-center justify-between gap-x-2", children: [_jsx("div", { className: "shrink-0 text-xs text-secondary", children: makeHumanizedTime(thread.created_at) }), _jsx(Dropdown, { trigger: _jsx(Button, { variant: "ghost", size: "icon-xs", className: "text-secondary", children: _jsx(MoreHorizontalIcon, { className: "size-4" }) }), children: _jsxs(DropdownItem, { className: "dropdown-item", onSelect: function () {
                                deleteThreadMutation.mutate();
                            }, children: [_jsx(TrashIcon, { className: "size-4" }), _jsx("span", { children: "Delete thread" })] }) })] })] }, thread.id));
};
