import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Hotkey } from "@cloudy/ui";
import { SiGithub } from "@icons-pack/react-simple-icons";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Placeholder from "@tiptap/extension-placeholder";
import Text from "@tiptap/extension-text";
import { EditorContent, Extension, useEditor } from "@tiptap/react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Markdown } from "tiptap-markdown";
import { Button } from "src/components/Button";
import { useWorkspace } from "src/stores/workspace";
import { makeNewProjectUrl, makeProjectSettingsUrl, useProjectRepos } from "src/utils/projects";
import { getAllNodesWithType } from "src/utils/tiptap";
import { useProject } from "../projects/ProjectContext";
import { AiTextAreaContext } from "./AiTextAreaContext";
import { FileReferenceRow } from "./FileReferenceRow";
import { AiTextAreaMention, aiTextAreaMention } from "./aiTextAreaMention";
var useHasGitRepoConnected = function () {
    var project = useProject();
    var projectRepos = useProjectRepos(project === null || project === void 0 ? void 0 : project.id).data;
    return Boolean(project && projectRepos && projectRepos.length > 0);
};
export var AiTextArea = function (_a) {
    var _b;
    var onSubmit = _a.onSubmit, onSecondaryAction = _a.onSecondaryAction, onCancel = _a.onCancel, _c = _a.placeholder, placeholder = _c === void 0 ? "Ask a question or describe what you want to do" : _c, _d = _a.submitButtonText, submitButtonText = _d === void 0 ? "Submit" : _d, onEdit = _a.onEdit, existingLinkedFiles = _a.existingLinkedFiles, disableNewFileReference = _a.disableNewFileReference, secondaryButtonText = _a.secondaryButtonText, addButtonText = _a.addButtonText, _e = _a.showConnectTooltip, showConnectTooltip = _e === void 0 ? false : _e;
    var workspace = useWorkspace();
    var project = useProject();
    var hasGitRepoConnected = useHasGitRepoConnected();
    var _f = useState([]), fileReferences = _f[0], setFileReferences = _f[1];
    var _g = useState([]), mentionedPaths = _g[0], setMentionedPaths = _g[1];
    var handleSubmitRef = useRef(null);
    var extensions = useMemo(function () {
        var exts = [
            Document,
            Text,
            Paragraph,
            Placeholder.configure({
                placeholder: placeholder,
            }),
            Extension.create({
                name: "hotkeys",
                addKeyboardShortcuts: function () {
                    return {
                        Enter: function () {
                            var _a;
                            (_a = handleSubmitRef.current) === null || _a === void 0 ? void 0 : _a.call(handleSubmitRef);
                            return true;
                        },
                        "Mod-Enter": function () {
                            var _a;
                            (_a = handleSubmitRef.current) === null || _a === void 0 ? void 0 : _a.call(handleSubmitRef, true);
                            return true;
                        },
                        "Shift-Enter": function (_a) {
                            var editor = _a.editor;
                            return editor.commands.first(function (_a) {
                                var commands = _a.commands;
                                return [
                                    function () { return commands.newlineInCode(); },
                                    function () { return commands.createParagraphNear(); },
                                    function () { return commands.liftEmptyBlock(); },
                                    function () { return commands.splitBlock(); },
                                ];
                            });
                        },
                        Escape: function () {
                            onCancel();
                            return true;
                        },
                    };
                },
            }),
            Markdown,
        ];
        if (!disableNewFileReference) {
            exts.push(AiTextAreaMention.configure({ suggestion: aiTextAreaMention }));
        }
        return exts;
    }, [disableNewFileReference]);
    var editor = useEditor({
        extensions: extensions,
        content: "",
        editable: true,
        autofocus: true,
        editorProps: {
            attributes: {
                class: "no-scrollbar w-full resize-none appearance-none border-none bg-transparent text-sm outline-none",
            },
        },
        onUpdate: function (_a) {
            var editor = _a.editor;
            var newMentionedFiles = getAllNodesWithType(editor, "mention");
            var newMentionedPaths = new Set(newMentionedFiles.map(function (file) { return file.id; }));
            var currentMentionedPaths = new Set(mentionedPaths);
            var removedPaths = Array.from(currentMentionedPaths).filter(function (path) { return !newMentionedPaths.has(path); });
            if (removedPaths.length > 0) {
                var newFileReferences = fileReferences.filter(function (file) { return !(removedPaths.includes(file.path) && file.mentioned); });
                setFileReferences(newFileReferences);
            }
            setMentionedPaths(Array.from(newMentionedPaths));
        },
    });
    useEffect(function () {
        if (editor) {
            editor.commands.focus();
        }
    }, [editor]);
    var hasContent = ((_b = editor === null || editor === void 0 ? void 0 : editor.getText().trim().length) !== null && _b !== void 0 ? _b : 0) > 0;
    var handleSubmit = useCallback(function (isSecondaryAction) {
        if (isSecondaryAction === void 0) { isSecondaryAction = false; }
        if (editor && (editor === null || editor === void 0 ? void 0 : editor.getText().trim())) {
            var content = editor.storage.markdown.getMarkdown();
            if (isSecondaryAction) {
                onSecondaryAction === null || onSecondaryAction === void 0 ? void 0 : onSecondaryAction(content, fileReferences);
            }
            else {
                onSubmit(content, fileReferences);
            }
            setFileReferences([]);
            setMentionedPaths([]);
            editor.commands.clearContent();
        }
    }, [editor, onSubmit, fileReferences, hasContent]);
    handleSubmitRef.current = handleSubmit;
    return (_jsx(AiTextAreaContext.Provider, { value: { fileReferences: fileReferences, setFileReferences: setFileReferences, existingLinkedFiles: existingLinkedFiles !== null && existingLinkedFiles !== void 0 ? existingLinkedFiles : [] }, children: _jsxs("div", { className: "relative flex w-full flex-col gap-4", children: [_jsx(EditorContent, { editor: editor }), _jsxs("div", { className: "flex flex-row items-start justify-between gap-2", children: [hasGitRepoConnected ? (_jsx(FileReferenceRow, { disableAdd: disableNewFileReference, addButtonText: addButtonText, showConnectTooltip: showConnectTooltip })) : project ? (_jsx(Link, { to: makeProjectSettingsUrl(workspace.slug, project.slug), children: _jsxs(Button, { size: "xs", variant: "outline", children: [_jsx(SiGithub, { className: "size-3" }), _jsx("span", { children: "Connect a git repo to reference files" })] }) })) : (_jsx("div", { className: "flex-1 overflow-hidden", children: _jsx(Link, { to: makeNewProjectUrl(workspace.slug), className: "block", children: _jsxs(Button, { size: "xs", variant: "outline", className: "mt-0.5 w-full", children: [_jsx(SiGithub, { className: "size-3 shrink-0" }), _jsx("span", { className: "truncate", children: "Create a project with a git repo to reference files" })] }) }) })), _jsxs("div", { className: "flex shrink-0 flex-row gap-1", children: [onSecondaryAction && (_jsxs(Button, { size: "sm", variant: "ghost", className: "text-accent", onClick: function () { return handleSubmit(true); }, disabled: !hasContent, children: [_jsx(Hotkey, { keys: ["cmd", "Enter"] }), _jsx("span", { children: secondaryButtonText })] })), _jsxs(Button, { size: "sm", variant: "default", onClick: function () { return handleSubmit(); }, disabled: !hasContent, children: [_jsx(Hotkey, { keys: ["Enter"] }), _jsx("span", { children: submitButtonText })] })] })] })] }) }));
};
