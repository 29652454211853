import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { EditorContent, useEditor } from "@tiptap/react";
import { PenIcon } from "lucide-react";
import { useEffect } from "react";
import { Button } from "../../components/Button";
import { useDocumentContext } from "./DocumentContext";
import { useLatestDocumentVersionContext } from "./LatestDocumentVersionContext";
import { tiptapExtensions } from "./editor/tiptap";
import { NavBar } from "./navBar/NavBar";
export var PublishedDocumentView = function () {
    var setIsEditMode = useDocumentContext().setIsEditMode;
    var latestDocumentVersion = useLatestDocumentVersionContext().latestDocumentVersion;
    var editor = useEditor({
        editorProps: {
            attributes: { class: "main-editor" },
        },
        extensions: tiptapExtensions,
        content: "",
        editable: false,
    });
    useEffect(function () {
        if (latestDocumentVersion) {
            editor === null || editor === void 0 ? void 0 : editor.commands.setContent(latestDocumentVersion.content_json
                ? latestDocumentVersion.content_json
                : latestDocumentVersion.content_html || latestDocumentVersion.content_md);
        }
    }, [latestDocumentVersion, editor]);
    return (_jsxs("div", { className: "no-scrollbar relative box-border flex flex-grow flex-col items-center overflow-y-scroll", children: [_jsx("nav", { className: "sticky top-[-1px] z-30 -mr-2 w-full bg-background px-6 py-2 md:top-0 md:py-3", children: _jsx(NavBar, { editor: editor }) }), _jsxs("div", { className: "box-border flex w-full max-w-screen-lg grow flex-col px-6 md:px-20 md:pt-16 lg:flex-1", children: [_jsx("h1", { className: "mb-4 text-2xl font-bold leading-8 outline-none md:text-3xl md:leading-10", children: latestDocumentVersion === null || latestDocumentVersion === void 0 ? void 0 : latestDocumentVersion.title }), _jsx("div", { 
                        // On larger screens, we need left padding to avoid some characters being cut off
                        className: "relative flex flex-row md:pl-[2px]", children: latestDocumentVersion ? (_jsx(EditorContent, { editor: editor, className: "w-full" })) : (_jsx("div", { className: "flex w-full flex-col items-center justify-center", children: _jsxs("div", { className: "flex flex-col items-center justify-center rounded border border-dashed border-border p-4 text-center text-tertiary", children: [_jsx("span", { children: "This document hasn't been published yet, open the editor to publish the first version." }), _jsxs(Button, { className: "mt-4", onClick: function () { return setIsEditMode(true); }, children: [_jsx(PenIcon, { className: "size-4" }), _jsx("span", { children: "Open Editor" })] })] }) })) }), _jsx("div", { className: "h-[75dvh]" })] })] }));
};
