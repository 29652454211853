import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { PrDraftDocumentStatus } from "@cloudy/utils/common";
import { BookDashedIcon, FileCheckIcon, XIcon } from "lucide-react";
import { Button } from "src/components/Button";
import { useConvertBackToDraft, useUpdateDocumentDraft } from "src/utils/prDrafts";
export var DraftingButtons = function (_a) {
    var _b;
    var documentDraft = _a.documentDraft;
    var updateDocumentDraftMutation = useUpdateDocumentDraft();
    var convertBackToDraftMutation = useConvertBackToDraft();
    if (!documentDraft) {
        return null;
    }
    var status = documentDraft.status;
    var buttonStates = (_b = {},
        _b[PrDraftDocumentStatus.PUBLISHED] = null,
        _b[PrDraftDocumentStatus.DRAFT] = (_jsxs(_Fragment, { children: [_jsxs(Button, { variant: "outline", size: "sm", onClick: function (e) {
                        e.preventDefault();
                        e.stopPropagation();
                        updateDocumentDraftMutation.mutate({
                            docId: documentDraft.document_id,
                            update: { status: PrDraftDocumentStatus.SKIPPED },
                        });
                    }, children: [_jsx(XIcon, { className: "size-4" }), "Skip"] }), _jsxs(Button, { size: "sm", variant: "outline", className: "text-accent", onClick: function (e) {
                        e.preventDefault();
                        e.stopPropagation();
                        updateDocumentDraftMutation.mutate({
                            docId: documentDraft.document_id,
                            update: { status: PrDraftDocumentStatus.CONFIRMED },
                        });
                    }, children: [_jsx(FileCheckIcon, { className: "size-4" }), "Confirm to Publish"] })] })),
        _b[PrDraftDocumentStatus.CONFIRMED] = (_jsx(_Fragment, { children: _jsxs(Button, { variant: "outline", size: "sm", onClick: function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    convertBackToDraftMutation.mutate({
                        docId: documentDraft.document_id,
                        prMetadataId: documentDraft.pr_metadata_id,
                    });
                }, children: [_jsx(BookDashedIcon, { className: "size-4" }), "Revert to Draft"] }) })),
        _b[PrDraftDocumentStatus.SKIPPED] = (_jsx(_Fragment, { children: _jsxs(Button, { size: "sm", variant: "outline", className: "text-accent", onClick: function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    convertBackToDraftMutation.mutate({
                        docId: documentDraft.document_id,
                        prMetadataId: documentDraft.pr_metadata_id,
                    });
                }, children: [_jsx(BookDashedIcon, { className: "size-4" }), "Convert back to Draft"] }) })),
        _b);
    return _jsx("div", { className: "flex flex-row items-center gap-2", children: buttonStates[status] });
};
