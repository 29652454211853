var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { handleSupabaseError } from "@cloudy/utils/common";
import { useMutation } from "@tanstack/react-query";
import { FolderCodeIcon } from "lucide-react";
import { queryClient } from "src/api/queryClient";
import { thoughtQueryKeys } from "src/api/queryKeys";
import { supabase } from "src/clients/supabase";
import { Dropdown } from "src/components/Dropdown";
import { HelpTooltip } from "src/components/HelpTooltip";
import LoadingSpinner from "src/components/LoadingSpinner";
import { FileReferenceRowStandalone } from "src/views/aiTextArea/FileReferenceRow";
import { useDocumentContext } from "../DocumentContext";
import { useExistingLinkedFiles } from "../editor/hooks";
var useExitingLinkedFilesAsRepoReferences = function () {
    var _a;
    var documentId = useDocumentContext().documentId;
    var queryResult = useExistingLinkedFiles(documentId);
    return __assign(__assign({}, queryResult), { data: (_a = queryResult.data) === null || _a === void 0 ? void 0 : _a.map(function (file) {
            return (__assign(__assign({}, file), { type: "file" }));
        }) });
};
var useSetFileReferences = function () {
    var documentId = useDocumentContext().documentId;
    return useMutation({
        mutationFn: function (fileReferences) { return __awaiter(void 0, void 0, void 0, function () {
            var existingLinks, _a, linksToDelete, linksToAdd, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _a = handleSupabaseError;
                        return [4 /*yield*/, supabase.from("document_repo_links").select("*").eq("document_id", documentId)];
                    case 1:
                        existingLinks = _a.apply(void 0, [_d.sent()]);
                        linksToDelete = existingLinks.filter(function (existing) { return !fileReferences.some(function (file) { return file.path === existing.path; }); });
                        linksToAdd = fileReferences.filter(function (file) { return !existingLinks.some(function (existing) { return existing.path === file.path; }); });
                        if (!(linksToDelete.length > 0)) return [3 /*break*/, 3];
                        _b = handleSupabaseError;
                        return [4 /*yield*/, supabase
                                .from("document_repo_links")
                                .delete()
                                .eq("document_id", documentId)
                                .in("path", linksToDelete.map(function (link) { return link.path; }))];
                    case 2:
                        _b.apply(void 0, [_d.sent()]);
                        _d.label = 3;
                    case 3:
                        if (!(linksToAdd.length > 0)) return [3 /*break*/, 5];
                        _c = handleSupabaseError;
                        return [4 /*yield*/, supabase.from("document_repo_links").insert(linksToAdd.map(function (file) { return ({
                                document_id: documentId,
                                repo_connection_id: file.repoConnectionId,
                                path: file.path,
                                type: "file",
                            }); }))];
                    case 4:
                        _c.apply(void 0, [_d.sent()]);
                        _d.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        }); },
        onMutate: function (fileReferences) {
            queryClient.setQueryData(thoughtQueryKeys.existingLinkedFiles(documentId), fileReferences);
            return { fileReferences: fileReferences };
        },
        onSuccess: function () {
            queryClient.invalidateQueries({ queryKey: thoughtQueryKeys.existingLinkedFiles(documentId) });
        },
    });
};
export var LinkedFilesDropdown = function (_a) {
    var trigger = _a.trigger;
    var _b = useExitingLinkedFilesAsRepoReferences(), linkedFiles = _b.data, isLoading = _b.isLoading;
    var setFileReferencesMutation = useSetFileReferences();
    return (_jsx(Dropdown, { trigger: trigger, children: _jsxs("div", { className: "flex w-[28rem] flex-col gap-2 p-4", children: [_jsxs("h5", { className: "mb-2 flex items-center gap-1 text-sm font-medium text-secondary", children: [_jsx(FolderCodeIcon, { className: "size-4 text-secondary" }), _jsx("span", { children: "Linked Files" }), _jsx(HelpTooltip, { content: _jsxs(_Fragment, { children: ["Link this document to files in your git repository.", _jsx("br", {}), _jsx("br", {}), "Linked files will be included as context to all AI functionality and will be used to keep this document up to date."] }) }), isLoading && _jsx(LoadingSpinner, { size: "xs", className: "ml-2" })] }), _jsx(FileReferenceRowStandalone, { fileReferences: linkedFiles !== null && linkedFiles !== void 0 ? linkedFiles : [], setFileReferences: setFileReferencesMutation.mutate, showUnlinkIconInsteadOfX: true })] }) }));
};
